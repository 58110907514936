import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import WebDevelopmentImg from "../../components/images/web/web-development"

export default function WebDevelopment() {
    return (
        <>
            <Layout>
                <SEO title="Web Development" />
                <Display
                    title="Web Development"
                    description="Whether you need a quick website refresh or a new build, make sure your website is a thing of beauty for desktop, laptop and mobile users."
                    prev="/web/copywriting"
                    next="/web/seo"
                    image={<WebDevelopmentImg />}
                />
            </Layout>                
        </>
    )
}
